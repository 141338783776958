import Swiper, { Pagination, EffectFade, Autoplay, Navigation, Scrollbar } from "swiper";
Swiper.use([Pagination, EffectFade, Autoplay, Navigation, Scrollbar]);


const slider = '.sliders-swiper-js';
const sliderCards = '.sliders-cards-js';

if(document.querySelector(slider)) {
    const sliderSwiper = new Swiper(slider, {
        slidesPerView: 1,
        spaceBetween: 30,
        effect: "fade",
        fadeEffect: {
            crossFade: true,
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    })
}

if(document.querySelector(sliderCards)) {
    const sliderCardsSwiper = new Swiper(sliderCards, {
        spaceBetween: 35,
        slidesPerView: 1,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            768: {
                slidesPerView: 2,
            },
            1200: {
                slidesPerView: 3,
                spaceBetween: 35,
            }
        },
        scrollbar: {
            el: '.swiper-scrollbar',
            draggable: true,
        },
    })
}


function elementInViewport(el){
    var bounds = el.getBoundingClientRect();
    return (
        (bounds.top + bounds.height > 0) && // Елемент ниже верхней границы
        (window.innerHeight - bounds.top > 0) && // Выше нижней
        (bounds.left + bounds.width > 0) && // Правее левой
        (window.innerWidth - bounds.left > 0)// Левее правой
    );
}
document.addEventListener("scroll", (e) => {
    const type = document.querySelector('#type');
    var inViewport = elementInViewport(type);
    if(inViewport) {
        type.classList.add('animation')
    }
})

$("#range").ionRangeSlider({
    min: 33,
    max: 59,
    from: 33
});

$("#range1").ionRangeSlider({
    min: 33,
    max: 59,
    from: 33
});