import './go-top/go-top'

// Magnific Popup
// import './magnific-popup/init'

// Owl Carousel
// import './owl-carousel/init'

// Simple ajax form
// import './simple-ajax-form-submit/jquery.simple-ajax-form'

// Fixed block
// import './fixedblock'

// Rangeslider
// import './rangeSlider/ion.rangeSlider'