// import simpleParallax from 'simple-parallax-js';

// Form
import './form/form'


// Hamburger
import './overlay/overlay'
import './header/header'
import './hamburger/hamburger'

import './modal/modal'
import './footer/footer'

